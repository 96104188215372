import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class OfflineService {
  private _offline: boolean;

  constructor(
    private router: Router,
    private dialog: MatDialog,
  ) { }

  get isOffline(): boolean {
    return this._offline;
  }

  setOfflineBehavior() {
    this._offline = !navigator.onLine;
    window.addEventListener('online', this.onOnline.bind(this));
    window.addEventListener('offline', this.onOffline.bind(this));
  }

  clearOfflineBehavior() {
    window.removeEventListener('online', this.onOnline.bind(this));
    window.removeEventListener('offline', this.onOffline.bind(this));
  }

  check() {
    if (this._offline) this.onOffline(); else this.onOnline();
  }

  private onOnline() {
    // solo redireccionar a / si ya estaba previamente en pantalla 'sin conexion'
    if (this.router.url === '/sin-conexion') {
      this._offline = false;
      this.router.navigate(['/'], { replaceUrl: true });
    }
  }

  private onOffline() {
    this._offline = true;
    this.dialog.closeAll();
    this.router.navigate(['/sin-conexion'], { replaceUrl: true });
  }
}
